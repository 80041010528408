import React, {useState, useContext, useRef, useEffect} from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../Context/AppContext';
import { NavigationPromptContext } from '../../Context/NavigationPromptContext';
import { HomePageContext } from '../../Context/HomePageContext';
import { useModal } from '../../Context/ModalContext';
// Components
import EditNoteCard from './EditNoteCard';
import NoteCardActions from './NoteCardActions';
import ResourceTypeIcon from '../ResourceCard/ResourceTypeIcon';
import CardSubject from '../CardSubject';
import NoteInfo from './NoteInfo';
import CustomLink from '../CustomLink';
// Packages
import { putNote } from '@linko/shared_utils';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

const NoteCard = ({ 
  note: originalNoteProp, 
  onDelete, 
  // onUpdate, 
  updateSingleNote,
  shareView: initialShareView, 
  currentNote, 
  revisitTab, 
  }) => {
  
  const location = useLocation();
  const { userInfo } = useContext(UserContext);  
  const { safeNavigate, registerEditorChanges } = useContext(NavigationPromptContext);
  const { updateSingleNoteInLibrary } = useContext(HomePageContext);

  const [noteProp, setNoteProp] = useState(originalNoteProp);

  const [title, setTitle] = useState(noteProp.title || '');
  const [note, setNote] = useState(noteProp?.note || '');
  const [noteResource, setNoteResource] = useState(noteProp?.resource?.[0] || null);
  const [isPrivate, setIsPrivate] = useState(noteProp?.private || false); 
  const [subject, setSubject] = useState(noteProp?.knowledge || null);
  const [showTextArea, setShowTextArea] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [noteLikes] = useState(noteProp.liked_by);
  const [contentOverflowed, setContentOverflowed] = useState(false);
  const [isExpanded, setIsExpanded] = useState((currentNote || revisitTab) ? true : false);
  const [shareView, setShareView] = useState(initialShareView);
  const [selectedImage, setSelectedImage] = useState(null);

  const noteContentRef = useRef(null);

  const { requestInfo, requestConfirm, requestAlert } = useModal();

  // Ensure noteProp.id is a string before calling replace
  const noteId = noteProp.id;
  const lastEditTime = new Date(noteProp.create_time);
  const formattedTime = lastEditTime.toLocaleDateString('en-US');
  const myNotePage = location.pathname.startsWith('/n/') && userInfo && noteProp.user && userInfo.id === noteProp.user.id;

  // Replace editorState with markdownContent
  const [markdownContent, setMarkdownContent] = useState(noteProp.note || '');

  // Add these refs near the other state declarations
  const initialTitleRef = useRef(noteProp.title || '');
  const initialNoteRef = useRef(noteProp?.note || '');
  const initialIsPrivateRef = useRef(noteProp?.private || false);
  const [editorContent, setEditorContent] = useState(noteProp?.note || '');

  useEffect(() => {
    if (myNotePage) { 
      setShareView(false);
    } else {
      setShareView(initialShareView);
    }
  }, [location.pathname, userInfo, noteProp]);

  useEffect(() => {
    if (noteProp.note) {
      setMarkdownContent(noteProp.note);
    }
  }, [noteProp.note]);

  // Detect overflow in note content
  useEffect(() => {
    if (noteContentRef.current) {
      if (noteContentRef.current.scrollHeight > noteContentRef.current.clientHeight || markdownContent.includes('![](')) {
        setContentOverflowed(true);
      } else {
        setContentOverflowed(false);
      }
    }
  }, [markdownContent]);

  const renderMarkdown = (content) => {
    // Pre-process the content to:
    // 1. Handle Chinese text with asterisks
    // 2. Add double spaces at the end of each blockquote line for line breaks
    const processedContent = content
      .replace(/(\*\*)([^*\n]+)(\*\*)/g, '<strong>$2</strong>')
      // Add two spaces before newline in blockquotes for markdown line breaks
      .replace(/^(>.*?)$/gm, '$1  ');

    const components = {
      em: ({node, ...props}) => <i {...props} />,
      strong: ({node, ...props}) => <strong style={{fontWeight: '600'}} {...props} />,
      u: ({node, ...props}) => <u {...props} />,
      pre: ({node, ...props}) => <pre {...props} />,
      blockquote: ({ children }) => {
        return (
          <blockquote className="note-blockquote">
            {children}
          </blockquote>
        );
      },
      h1: ({node, ...props}) => <h1 {...props} />,
      h2: ({node, ...props}) => <h2 {...props} />,
      ol: ({node, ordered, depth, ...props}) => <ol className={`list-depth-${depth}`} {...props} />,
      ul: ({node, ordered, depth, ...props}) => <ul className={`list-depth-${depth}`} {...props} />,
      li: ({node, ordered, checked, index, ...props}) => {
        return <li {...props} />;
      },
      p: ({node, ...props}) => (
        <p 
          style={{
            margin: '8px 0',
            lineHeight: '1.5'
          }} 
          {...props} 
        />
      ),
      a: ({node, ...props}) => (
        <a 
          {...props} 
          target="_blank" 
          rel="noopener noreferrer" 
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      ),
      img: ({node, ...props}) => {
        const src = props.src || (props.node && props.node.properties && props.node.properties.src);
        return (
          <img 
            src={src} 
            alt="" 
            style={{maxWidth: '90%', height: 'auto', cursor: 'zoom-in'}} 
            onClick={(e) => {
              e.stopPropagation();
              setSelectedImage(src);
            }}
          />
        );
      },
    };
    
    try {
      return (
        <ReactMarkdown 
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={components}
          allowedElements={['p', 'br', 'strong', 'em', 'u', 'pre', 'blockquote', 'h1', 'h2', 'ol', 'ul', 'li', 'a', 'img']}
          unwrapDisallowed={true}
        >
          {processedContent}
        </ReactMarkdown>
      );
    } catch (error) {
      console.error('Error rendering markdown:', error);
      return <pre>{content}</pre>; // Fallback to displaying raw content
    }
  };
  
  const handleEditClick = () => {
    setShowTextArea(true);
    setTitle(noteProp.title);
    setNote(noteProp.note);
    setNoteResource(noteProp.resource?.[0]);
    setIsPrivate(noteProp.private);
  };

  const handleCancel = async () => {
    const hasChanges = 
      title !== initialTitleRef.current ||
      note !== initialNoteRef.current ||
      isPrivate !== initialIsPrivateRef.current;

    if (hasChanges) {
      requestConfirm(
        'You have unsaved changes. Are you sure you want to discard them?',
        () => {
          setTitle(initialTitleRef.current);
          setNote(initialNoteRef.current);
          setIsPrivate(initialIsPrivateRef.current);
          setEditorContent(initialNoteRef.current);
          setShowTextArea(false);
        },
        () => {
          // Do nothing, keep editing
        },
        'Discard'
      );
    } else {
      setTitle(initialTitleRef.current);
      setNote(initialNoteRef.current);
      setIsPrivate(initialIsPrivateRef.current);
      setEditorContent(initialNoteRef.current);
      setShowTextArea(false);
    }
  };

  const saveEdit = async () => {
    try {
      const newNote = {
        title: title,
        note: note,
        private: isPrivate,
      };
      await putNote(noteId, newNote, null, null, noteResource?.id);
      
      // Update local state and UI
      setNoteProp(prevNote => ({
        ...prevNote,
        title: title,
        note: note,
        private: isPrivate,
      }));
      
      setTitle(title);
      setIsPrivate(isPrivate);
      setMarkdownContent(note);
      setShowTextArea(false);
      
      // Register that this editor no longer has changes
      registerEditorChanges(noteId, false);
      if (updateSingleNote && !location.pathname.startsWith('/my_linko')) {
        updateSingleNote(noteId);
      }
      // Update the note in the home page library
      updateSingleNoteInLibrary(noteId);

    } catch (error) {
      console.error('Error saving note:', error);
      setNoteProp(prevNote => ({
        ...prevNote,
        title: noteProp.title,
        note: noteProp.note,
        private: noteProp.private,
      }));
      requestAlert('Failed to save note. Please try again.');
    }
  };

  const seeNoteLikes = () => {
    requestInfo({
      data: noteLikes, 
      type: 'noteLikes' 
    });
  };

  const handleNoteClick = (e) => {
    // Check if user is selecting text
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return; // Don't navigate if text is selected
    }

    const isLink = e.target.tagName === 'A' || e.target.closest('a');
    if (isLink) {
      return;
    }

    if (contentOverflowed) {
      if (isExpanded) {
        safeNavigate(`/n/${noteId}`);
      } else {
        setIsExpanded(true);
      }
    } else if (!contentOverflowed) {
      safeNavigate(`/n/${noteId}`);
    }
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  if (showTextArea) {
    return (
      <EditNoteCard 
        isLoading={isLoading}
        showTextArea={showTextArea}

        handleSubmit={saveEdit}
        handleCancel={handleCancel}
        updateSingleNote={updateSingleNote}

        title={title}
        setTitle={setTitle}
        note={note}
        setNote={setNote}
        isPrivate={isPrivate}
        setIsPrivate={setIsPrivate}
        noteResource={noteResource}
        setNoteResource={setNoteResource}
        editorContent={editorContent}
        setEditorContent={setEditorContent}
        noteId={noteId}
      />
    );
  }

  return (
    <div className='note-card-wrapper'>
      <div className='note-card'>
        <div className='note-card-header'>
          <div className='note-card-content'>
            {title !== null && title !== undefined && (
              <CustomLink
                to={`/n/${noteId}`}
                className='note-title'
                style={{
                  cursor: currentNote ? 'default': 'pointer',
                  textDecoration: 'none',
                  color: 'inherit'
                }}
                onClick={(e) => {
                  if (currentNote) {
                    e.preventDefault();
                  }
                }}
              >
                {title}
              </CustomLink>
            )}
            <div
              onClick={handleNoteClick}
              className={`note-content-text ${(contentOverflowed && !isExpanded) ? 'overflowed' : ''}`}
              style={{ 
                maxHeight: isExpanded ? 'fit-content' : '175px', 
                overflow: 'hidden', 
                cursor: currentNote ? 'default': 'pointer',
                display: 'block'
              }}
              ref={noteContentRef}
            >
              {renderMarkdown(markdownContent)}
            </div>
            <NoteInfo
              contentOverflowed={contentOverflowed}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
              noteProp={noteProp}
              noteLikes={noteLikes}
              formattedTime={formattedTime}
              seeNoteLikes={seeNoteLikes}
              shareView={shareView}
              currentNote={currentNote}
            />
            <CardSubject 
              subject={subject} 
              setSubject={setSubject}
              shareView={shareView}
              noteId={noteId}
              updateSingleNote={updateSingleNote}
              />
          </div>
          {!shareView && 
            <NoteCardActions 
              note={noteProp} 
              noteId={noteId}
              onEditClick={handleEditClick} 
              onDelete={onDelete}
              updateSingleNote={updateSingleNote}
              noteResource={noteResource}
              setNoteResource={setNoteResource}
              myNotePage={myNotePage}
            />
          }
        </div>
        {noteResource?.title && <div className='rx-divider-vertical' style={{width: 'calc(100% - 40px)' , margin:'0 20px'}}/>}
        {noteResource?.title && (
          <CustomLink 
            to={`/r/${noteResource.id}`}
            className='note-card-footer'
            style={{ 
              textDecoration: 'none', 
              color: 'inherit',
              cursor: location.pathname === `/r/${noteResource.id}` ? 'default' : 'pointer'
            }}
            onClick={(e) => {
              if (location.pathname === `/r/${noteResource.id}`) {
                e.preventDefault();
              }
            }}
          >
              <div className='note-card-resource'>
                <ResourceTypeIcon type={noteResource.type} size={24}/>
                  <p className='note-card-resource-title'>
                    {noteResource.title}                      
                  </p>
              </div>
              {noteResource.author && (
                <p className='note-card-resource-author'> By {noteResource.author}</p>
              )}
          </CustomLink>
        )}
      </div>
      {selectedImage && (
        <div 
          className="image-modal" 
          onClick={handleCloseModal}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000,
            cursor: 'zoom-out'
          }}
        >
          <img 
            src={selectedImage} 
            alt="Enlarged view" 
            onClick={handleCloseModal}
            style={{
              maxWidth: '90vw',
              maxHeight: '90vh',
              objectFit: 'contain'
            }}
          />
        </div>
      )}
    </div>
  );
};

export default NoteCard;
