import React, { createContext, useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchMyData, fetchUserFollow, getNotifCount, fetchUserSubject, useWindowSize } from '@linko/shared_utils';

export const UserContext = createContext();
export const FollowContext = createContext();
export const AuthContext = createContext();
export const AddNoteCardContext = createContext();
export const OnboardingContext = createContext();
export const LoadingContext = createContext();
export const SidebarContext = createContext();

export function AppContextProvider({ children }) {

  const [userInfo, setUserInfo] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [followings, setFollowings] = useState([]);
  const [followers, setFollowers] = useState([]);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [requesting, setRequesting] = useState([]);
  const [addNoteCard, setAddNoteCard] = useState(false);
  const [newNoteContent, setNewNoteContent] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const [notifCount, setNotifCount] = useState(0);
  const [newFeedCount, setNewFeedCount] = useState(0);
  const [newRequestCount, setNewRequestCount] = useState(0);
  const [userNoteCount, setUserNoteCount] = useState(null);
  const [userResourceCount, setUserResourceCount] = useState(0);
  const [userSubject, setUserSubject] = useState([]);

  const [userCounts, setUserCounts] = useState([]);

  // Authenticated
  const checkAuthStatus = () => {
    const token = localStorage.getItem('access_token');
    return token !== null && token !== '';
  };
  const [isAuthenticated, setIsAuthenticated] = useState(checkAuthStatus());

  const handleWebsiteLogout = () => {
    setIsAuthenticated(false);
    navigate('/login');
  };

  useEffect(() => {
    const handleTokenExpired = () => {
      handleWebsiteLogout();
    };

    window.addEventListener('session-expired', handleTokenExpired);
    return () => {
      window.removeEventListener('session-expired', handleTokenExpired);
    };
  }, [handleWebsiteLogout]);

  useEffect(() => {
    const protectedRoutes = ['/login', '/register', '/forget_password', '/'];
    if (isAuthenticated && protectedRoutes.includes(location.pathname)) {
      navigate('/my_linko');
    }
  }, [location, isAuthenticated, navigate]);

  const fetchFollowData = async () => {
    const data = await fetchUserFollow();
    setFollowings(data.following);
    setFollowers(data.follower);
    setPendingRequests(data.request_pending);
    setRequesting(data.requesting);
  };

  const fetchUserInfo = async () => {
    const data = await fetchMyData();
    setUserInfo(data);
    setUserCounts(data);
    setUserNoteCount(data.note_count);
    setUserResourceCount(data.resource_count);
    if (data && !data.private_account) {
      await fetchFollowData();
    }
  };

  const fetchNotifCount = async () => {
    try {
      const data = await getNotifCount();
      setNotifCount(data.new_likes + data.new_follow_requests);
      setNewRequestCount(data.new_follow_requests);
      setNewFeedCount(data.new_feed);
    } catch (error) {
      console.error('Error fetching notification count:', error);
    }
  };

  const fetchUserSubjectData = async () => {
    const data = await fetchUserSubject();
    setUserSubject(data.userSubject);
  };

  const fetchUserData = async () => {
    setIsLoading(true);
    await fetchUserInfo();
    await fetchUserSubjectData();
    setIsLoading(false);
  };

  useEffect(() => {    
    if (isAuthenticated) {
      fetchUserData();
    } else {
      setIsLoading(false);
      navigate('/login');
    }
  }, [isAuthenticated]);

  const userContextValue = {
    userInfo, 
    setUserInfo, 
    fetchUserInfo, 
    notifCount, 
    setNotifCount, 
    fetchNotifCount, 
    newFeedCount, 
    setNewFeedCount, 
    newRequestCount,
    userSubject,
    setUserSubject,
    userCounts,
    userNoteCount,
    setUserNoteCount,
    userResourceCount,
    setUserResourceCount,
    fetchUserSubjectData
  };
  
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      <AddNoteCardContext.Provider value={{ addNoteCard, setAddNoteCard, newNoteContent, setNewNoteContent }}>
          <FollowContext.Provider value={{ fetchFollowData, followings, setFollowings, followers, setFollowers, pendingRequests, setPendingRequests, requesting, setRequesting }}>
            <UserContext.Provider value={userContextValue}>
              <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, checkAuthStatus, handleWebsiteLogout}}>
                <SidebarProvider>
                  {children}
                </SidebarProvider>
              </AuthContext.Provider>
            </UserContext.Provider>
          </FollowContext.Provider>
      </AddNoteCardContext.Provider>
    </LoadingContext.Provider>
  );
}

export const SidebarProvider = ({ children }) => {
  const { userInfo } = useContext(UserContext);
  const [showSidebar, setShowSidebar] = useState(false);
  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 1089) {
      setShowSidebar(false);
    } else if (userInfo?.hide_sidebar !== undefined) {
      setShowSidebar(!userInfo.hide_sidebar);
    }
  }, [userInfo?.hide_sidebar, width]);

  return (
    <SidebarContext.Provider value={{ showSidebar, setShowSidebar }}>
      {children}
    </SidebarContext.Provider>
  );
};

export default AppContextProvider;